<ng-container *ngIf="categoryModalComponentService.refreshAllCategories$ | async" />
<ng-container *ngIf="focusSearchOnRefundChange$ | async" />
<div dougsModalTitle>
  <h6>Sélectionnez une catégorie</h6>
  <i class="ml-8 fal fa-times" dougsModalClose></i>
</div>
<div class="category-modal-content" dougsModalContent="large">
  <form [formGroup]="categoryModalComponentService.formGroup">
    <dougs-form-field>
      <i dougsFormFieldPrefix class="fal fa-search"></i>
      <input
        dougsAutofocus
        type="text"
        placeholder="Rechercher"
        formControlName="search"
        dougsFormFieldControl
        #searchInputElement
      />
    </dougs-form-field>

    <div class="category-modal-content__refund-category mb-16">
      @if (categoryModalComponentService.data.type !== 'investment') {
        <dougs-form-field [noMargin]="true">
          <dougs-checkbox
            formControlName="isRefund"
            label="S'agit-il d'un remboursement ?"
            appearance="toggle"
            [showYesNo]="true"
          ></dougs-checkbox>
        </dougs-form-field>
      }

      @if (categoryModalComponentService.shouldShowCategorizationHelp$ | async) {
        @if (categoryModalComponentService.hasAlreadySendHelp$()) {
          <p class="tiny">
            <dougs-pill type="warning" class="mr-8">Demande envoyée</dougs-pill>
            En cours de catégorisation par nos équipes
          </p>
        } @else {
          <p class="small color-primary-700">
            <i class="fal fa-question-circle color-primary-700 mr-8"></i>
            Besoin d'aide pour catégoriser votre opération&nbsp;?
            <a href="#" class="color-primary" (click)="categoryModalComponentService.openHelpCategoryModal($event)"
              >Contactez-nous</a
            >
          </p>
        }
      }
    </div>
  </form>
  @if (categoryModalComponentService.isLoading$()) {
    <dougs-loader />
  }

  @if (categoryModalComponentService.categories$ | async; as categories) {
    @if ((categories | emptyObject) && !categoryModalComponentService.isLoading$()) {
      @if (!(categoryModalComponentService.isExpenseSearch$ | async)) {
        <dougs-blank-state illustration="empty-search" class="category-blank-state">
          <h6 class="mb-4">Aucun résultat trouvé</h6>
          @if (categoryModalComponentService.shouldShowCategorizationHelpInBlankSlate$()) {
            <p class="small">
              Nos comptables peuvent vous aider,
              <a href="#" (click)="categoryModalComponentService.openHelpCategoryModal($event)">contactez-nous</a> !
            </p>
          }
        </dougs-blank-state>
        @if (categoryModalComponentService.isExpenseSearch$ | async) {
          <dougs-panel-info>
            <h6 class="mb-4">Besoin d'aide ?</h6>
            <p class="small color-primary-700 mb-8">
              Pour catégoriser une note de frais, vous devez indiquer le type de dépense correspondant. Par exemple :
            </p>
            <p class="small color-primary-700 mb-4"><b>Frais de repas</b> : sélectionner la catégorie "Restaurant"</p>
            <p class="small color-primary-700">
              <b>Abonnement téléphonique</b> : sélectionner la catégorie "Internet, téléphone, abonnement informatique
              et logiciel"
            </p>
          </dougs-panel-info>
        }
      }
    }
    <div class="scrollable">
      @if (categoryModalComponentService.shouldShowAvailableCategories$()) {
        @for (categoryGroup of categories; track categoryGroup[0].id) {
          <div class="category-group">
            <div class="category-group__title" [ngClass]="[categoryGroup[0]?.color || '']">
              {{ categoryGroup[0].name }}
            </div>
            <div class="category-group__content">
              @for (category of categoryGroup[1]; track 'id') {
                <div
                  class="category-group__content__category p-16"
                  [ngClass]="{
                    'category-group__content__category--even': categoryGroup[1].length % 2 === 0,
                    'category-group__content__category--selected':
                      categoryModalComponentService.data.categorySelected === category.id
                  }"
                  (click)="categoryModalComponentService.selectCategory(category)"
                >
                  <dougs-category-content
                    [category]="category"
                    [breakdownIsInbound]="categoryModalComponentService.data.breakdownIsInbound"
                  ></dougs-category-content>
                </div>
              }
            </div>
          </div>
        }
      }

      @if (categoryModalComponentService.shouldShowPreferredCategories$()) {
        <div class="preferred-categories-container">
          @for (category of categoryModalComponentService.preferredCategories$(); track category.id) {
            <div
              class="category-group__content__category category-group__content__category--prefered p-16"
              (click)="categoryModalComponentService.selectCategory(category)"
            >
              <dougs-category-content
                [category]="category"
                [isPreferred]="true"
                [breakdownIsInbound]="categoryModalComponentService.data.breakdownIsInbound"
              ></dougs-category-content>
            </div>
          }
        </div>
      }
    </div>
  }
</div>
@if (categoryModalComponentService.shouldShowFooter$ | async) {
  <div dougsModalFooter class="footer">
    <p class="tiny">
      Vous ne trouvez pas votre catégorie ? Seules les catégories les plus utilisées sont affichées. Si vous le
      souhaitez, vous pouvez
      <a class="text-button" (click)="categoryModalComponentService.onShowAllAvailableCategories()">
        afficher toutes les catégories&nbsp;&rarr;
      </a>
    </p>
  </div>
}
